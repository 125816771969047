import Button from '@core-ui/button';
import Modal from '@core-ui/modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { TextFieldForm } from '@/components/Form';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changePassword } from '@/pages/settings/Modals/ChangePasswordModal/actions';
import { getLoading } from '@/pages/settings/Modals/ChangePasswordModal/selector';
import validate from '@/pages/settings/Modals/ChangePasswordModal/validate';
import { IChangePassword } from '@/pages/settings/types';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';

interface IProps {
  opened: boolean;
  onClose: () => void;
}

const ChangePasswordModal = (props: IProps) => {
  const { opened, onClose } = props;

  const loading = useAppSelector(getLoading);
  const dispatch = useDispatch();

  const onSubmit = (values: IChangePassword) => {
    dispatch(changePassword(values));
  };

  return (
    <Modal
      open={opened}
      maxWidth="sm"
      onClose={onClose}
      title={<FormattedMessage id="modal.settings.change_password.title" />}
    >
      <Form<IChangePassword> keepDirtyOnReinitialize onSubmit={onSubmit} subscription={{}} validate={validate}>
        {({ handleSubmit }) => {
          return (
            <DialogContent>
              <Grid container item direction="column" gap={5} marginTop={5} component="form" onSubmit={handleSubmit}>
                <Field
                  name="old_password"
                  autoFocus
                  label={<FormattedMessage id="label.old_password" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={loading}
                  type="password"
                />

                <Field
                  name="new_password"
                  label={<FormattedMessage id="label.new_password" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={loading}
                  type="password"
                />

                <Field
                  name="confirm_new_password"
                  label={<FormattedMessage id="label.confirm_new_password" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={loading}
                  type="password"
                />

                <DialogActions>
                  <Button
                    color="blue"
                    variant="contained"
                    size="large"
                    label={<FormattedMessage id="label.save" />}
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  />
                </DialogActions>
              </Grid>
            </DialogContent>
          );
        }}
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
