import Button from '@core-ui/button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextFieldForm } from '@/components/Form';
import { EmployeeSchema } from '@/generated';
import { useAppSelector } from '@/hooks/useAppSelector';
import { openSettingsModal } from '@/pages/settings/Modals/actions';
import { ChangePasswordModalData } from '@/pages/settings/Modals/command';
import { updateEmployee } from '@/pages/settings/ProfileRoot/actions';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'components/Intl';
import { useDispatch } from 'react-redux';
import selector from './selector';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const { profile, formLoading } = useAppSelector(selector);

  const handleChangePasswordClick = () => {
    dispatch(openSettingsModal(new ChangePasswordModalData()));
  };

  const onSubmit = (values: EmployeeSchema) => {
    dispatch(updateEmployee(values));
  };

  return (
    <Grid container padding="32px 24px" spacing={10}>
      <Grid container item direction="column" xs={4}>
        <Typography variant="h4">
          <FormattedMessage id="label.user" />
        </Typography>

        <Form<EmployeeSchema>
          keepDirtyOnReinitialize
          initialValues={profile}
          onSubmit={onSubmit}
          subscription={{ submitting: true }}
        >
          {({ handleSubmit }) => {
            return (
              <Grid container item direction="column" gap={5} marginTop={5} component="form" onSubmit={handleSubmit}>
                <Field
                  name="first_name"
                  label={<FormattedMessage id="label.first_name" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={formLoading}
                />

                <Field
                  name="last_name"
                  label={<FormattedMessage id="label.last_name" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={formLoading}
                />

                <Field
                  name="what_i_do"
                  label={<FormattedMessage id="label.what_you_do" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={formLoading}
                />

                <Field
                  name="phone_number"
                  label={<FormattedMessage id="label.phone" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={formLoading}
                />

                <Field
                  name="email"
                  label={<FormattedMessage id="label.email" />}
                  component={TextFieldForm}
                  fullWidth
                  disabled={formLoading}
                />

                <Grid container item direction="column" gap={2}>
                  <Grid item>
                    <Button
                      compact
                      color="blue"
                      label={<FormattedMessage id="label.change_password" />}
                      onClick={handleChangePasswordClick}
                      disabled={formLoading}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      color="blue"
                      variant="contained"
                      label={<FormattedMessage id="label.save" />}
                      type="submit"
                      disabled={formLoading}
                      loading={formLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};

export default ProfileForm;
